<template>
  <security-page>
    <template #left>
      <page-illustration :src="illustration" />
    </template>
    <template #right>
      <check-email-content />
    </template>
  </security-page>
</template>

<script>
import SecurityPage from '@/views/foravila/security/components/SecurityPage.vue'
import PageIllustration from '@/views/foravila/security/components/PageIllustration.vue'
import CheckEmailContent from '@/views/foravila/security/components/CheckEmailContent.vue'

export default {
  components: {
    SecurityPage,
    PageIllustration,
    CheckEmailContent,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      illustration: require('@/assets/foravila/images/illustrations/sent-message.svg'),
    }
  },
}
</script>
